import React from "react";
import PropTypes from "prop-types";
import { System } from "../../commons/Constants";
import { QueryParam } from "../../commons/Constants";

export const CopyrightCashParking = ({ landerConfig, queryConfig }) => {
  const showDomainName =
    queryConfig[QueryParam.SHOW_DOMAIN] ||
    landerConfig.system !== System.PARKWEB;
  if (showDomainName) {
    // default is false for this template
    const domainName =
      landerConfig.lander.domainDisplayName || landerConfig.domain.rootDomain;
    return (
      <span id="copyright">
        Copyright &copy; {domainName}. &nbsp;All rights reserved.
      </span>
    );
  }
  return (
    <span id="copyright">Copyright &copy; &nbsp;All rights reserved.</span>
  );
};

CopyrightCashParking.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
};
