import { LanderCookie, FeedProvider, EventType, System } from "./Constants";
import {
  getCookie,
  getDomainName,
  getUrlProtocol,
  getVisitorIdentifier,
  isMobileBrowser,
} from "./HelperFunctions";
import { getPreformanceMetrics } from "./Performance";
import { getExpiryPartnerConfig } from "./LanderConfig";
import { postEvent as postEventHelper } from "./EventHelper";
import { AdBlockPlusStatus } from "./adBlockPlusStatus";

/**
 * Create a base lander event with standard field names
 * This is mainly created for naming convention
 * @param eventType parking event type
 * @param landerConfig lander param API response
 * @returns object
 *
 * Note: Pleas use caution while accessing nested object for `landerConfig`.
 * If landerConfig is an empty object (no lander-param for the domain),
 * accessing nested object results into an error.
 */
// eslint-disable-next-line complexity
export function createBaseLanderEvent(eventType, landerConfig) {
  const perf = getPreformanceMetrics();

  // empty obj {} are used to guard against nested access
  const domain = landerConfig.domain || {};
  const adSense = landerConfig.adSense || {};
  const lander = landerConfig.lander || {};
  const experiment = landerConfig.experiment || {};
  const expiryPartner = getExpiryPartnerConfig();
  const banner = lander.banner || {};
  const bannerType = banner.show ? banner.type : null;

  const domLoadTime = perf && perf.domComplete ? perf.domComplete() : 0;
  const loadEventEnd = perf && perf.loadEventEnd ? perf.loadEventEnd() : 0;

  return {
    eventType: eventType,
    errorCode: null,
    landerParamApiStatus: null,
    xRequestId: null,
    createdAt: new Date().toISOString(),
    domain: domain.rootDomain,
    domainStatus: (domain.status || {}).internal,
    system: landerConfig.system,
    dataSource: landerConfig.dataSource,
    isTurnKeyReseller: typeof landerConfig.turnKeyReseller !== "undefined",
    resellerPlid: (landerConfig.turnKeyReseller || {}).privateLabelId,
    isCname: landerConfig.dataSource === "CNAME",
    rotatorId: landerConfig.rotatorId,
    account: landerConfig.account,
    customerId: landerConfig.customerId ?? "",
    expiryPartner: expiryPartner.expiryPartner,
    portfolioType: expiryPartner.portfolioType,
    portfolioId: landerConfig.portfolioId,
    drid: adSense.drid,
    pubId: adSense.pubId,
    channel: adSense.channel,
    feedProvider: landerConfig.adSense ? FeedProvider.GOOGLE_AD_SENSE : null,
    template: lander.template,
    trafficTarget: getCookie(LanderCookie.TRAFFIC_TARGET),
    sToken: null,
    keyword: null,
    keywordOrigin: null,
    feedStatusErrorCode: null,
    verizonApiMessage: null,
    isAdult: null,
    bannerType: bannerType,
    experimentId: experiment.key,
    experimentBucket: experiment.bucket,
    gcpIp: getCookie(LanderCookie.GCP_LB_IP),
    ip: getCookie(LanderCookie.CLIENT_IP),
    country: getCookie(LanderCookie.COUNTRY),
    // remove quotes from city name ("San Francisco")
    city: getCookie(LanderCookie.CITY).replace(/["]+/g, ""),
    visitorId: getVisitorIdentifier(),
    protocol: getUrlProtocol(),
    userAgent: navigator.userAgent,
    httpReferer: document.referrer ? document.referrer : null,
    domLoadTime: domLoadTime,
    pageLoadTime: loadEventEnd,
    isMobile: isMobileBrowser(),
    adblocker: AdBlockPlusStatus,
  };
}

/**
 * Send FOS Redirect event
 * @param queryConfig
 */
export function createParkWebRedirectFosEvent(queryConfig) {
  return {
    eventType: EventType.REDIRECT_FOS,
    domain: getDomainName(queryConfig),
    createdAt: new Date().toISOString(),
    system: System.PARKWEB,
    ip: getCookie(LanderCookie.CLIENT_IP),
    country: getCookie(LanderCookie.COUNTRY),
    city: getCookie(LanderCookie.CITY),
    visitorId: getVisitorIdentifier(),
    protocol: getUrlProtocol(),
    userAgent: navigator.userAgent,
    httpReferer: document.referrer ? document.referrer : null,
    isMobile: isMobileBrowser(),
  };
}

export default async function postEvent(apiEndpoint, eventObj) {
  await postEventHelper(apiEndpoint, eventObj);
}
