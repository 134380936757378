import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { ParkingLander } from "./ParkingLander";
import { setCookie } from "./commons/HelperFunctions";
import { LanderCookie } from "./commons/Constants";
import { setAdBlockPlusStatus } from "./commons/adBlockPlusStatus";

// eslint-disable-next-line no-process-env
if (process.env.NODE_ENV === "development") {
  // This is set by nginx in prod
  setCookie(LanderCookie.CLIENT_IP, "24.48.65.61", 10000);
}

const renderAppComponent = () => {
  const root = document.getElementById("root");
  ReactDOM.render(<ParkingLander />, root);
};

setAdBlockPlusStatus();
renderAppComponent();
export default renderAppComponent;
