import { Settings } from "../../config";
import { QueryParam, EventType, ErrorCode } from "../../commons/Constants";
import Logger from "../../commons/Logger";
import {
  AdSense,
  getDefaultAdsOptions,
  getDefaultPageOptions,
  getDefaultRelatedLinkOptions,
  getDefaultSearchboxOptions,
} from "./adSenseUtils";
import { setupCallbackTracker } from "../AdSenseCallback";
import {
  getAdsConfig,
  getPageLevelParams,
  getRelatedLinksConfig,
  getSearchboxConfig,
} from "../layout/LayoutStyle";
import postEvent, { createBaseLanderEvent } from "../../commons/LanderEvent";

export default function loadAdsense(
  landerConfig,
  queryConfig,
  page,
  handleRequestAccepted
) {
  const lc = landerConfig;
  const qc = queryConfig;
  const template = lc.lander.template;

  if (!handleRequestAccepted)
    handleRequestAccepted = () => {
      /** Do nothing */
    };

  // Setup callback and tracking
  setupCallbackTracker(lc, qc);

  // Create the caf options:
  // Merge the template-specific options with the default settings.
  // Template-specific settings (right hand side of the spread operator) will override default options.

  const pageOptions = {
    ...getDefaultPageOptions(lc, handleRequestAccepted),
    ...getPageLevelParams(lc),
  };
  let pageContentOptions;

  // Page content - Related link or Sponsored listings
  if (page === AdSense.page.RELATED_LINKS) {
    pageContentOptions = {
      ...getDefaultRelatedLinkOptions(lc, qc),
      ...getRelatedLinksConfig(template),
    };
  } else {
    pageContentOptions = {
      ...getDefaultAdsOptions(lc, qc),
      ...getAdsConfig(template),
    };
  }

  // Call Google caf library
  // Searchbox options must be passed as the last argument. 2 separate calls also works.
  if (typeof window.google !== "undefined") {
    if (qc[QueryParam.SEARCH_BOX]) {
      Logger.debug("Google CAF request with searchbox on");
      const searchboxOptions = {
        ...getDefaultSearchboxOptions(lc, qc),
        ...getSearchboxConfig(template),
      };
      window.google.ads.domains.Caf(
        pageOptions,
        pageContentOptions,
        searchboxOptions
      );
    } else {
      Logger.debug("Google CAF request with searchbox off");
      window.google.ads.domains.Caf(pageOptions, pageContentOptions);
    }
  } else {
    Logger.info("window.google not found");
    const event = createBaseLanderEvent(EventType.ERROR, landerConfig);
    event.errorCode = ErrorCode.ERROR_ADSENSE_NO_CAF;
    postEvent(Settings.EVENT_PUBLISH_API, event);
    handleRequestAccepted(false);
  }
}
