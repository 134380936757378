import React from "react";
import PropTypes from "prop-types";
import { Settings } from "../../config";
import { FormattedMessage } from "react-intl";

export const PrivacyPolicy = ({ landerConfig }) => {
  const privacyUrl =
    (landerConfig.turnKeyReseller || {}).privacyUrl ||
    Settings.PRIVACY_POLICY_URL;

  function getModalPrivacy() {
    window.open(
      privacyUrl,
      "privacy",
      "width=520,height=400,left=250,top=250,menubar=no,status=yes,toolbar=no,scrollbars=yes,resizable=yes"
    );
  }
  return (
    <span id="privacy">
      <a href="#!" rel="nofollow" onClick={ getModalPrivacy }>
        <FormattedMessage id="privacyPolicyText" />
      </a>
    </span>
  );
};

PrivacyPolicy.propTypes = {
  landerConfig: PropTypes.object.isRequired,
};
