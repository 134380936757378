import React from "react";
import PropTypes from "prop-types";

const linkButtonStyle = {
  backgroundColor: "#111111",
  color: "white",
  fontSize: "16px",
  textAlign: "center",
  padding: "8px 16px 8px 16px",
};

const linkStyle = {
  color: "white",
  fontWeight: "bold",
  textDecoration: "none",
};

export function LinkButton({ link, onClick, children }) {
  return (
    <div style={ linkButtonStyle }>
      <a href={ link } onClick={ onClick } style={ linkStyle }>
        {children}
      </a>
    </div>
  );
}

LinkButton.propTypes = {
  link: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.element,
};
